@import './variables.scss';

/* BTN  */
.btn {
    font-family: $fontPoppin;
    font-weight: 500;
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    gap: 1rem;
    padding: 14px 20px;
    @apply shadow;
    &:hover{
        @apply shadow-md;
    }
}

.btn--basic {
    font-family: $fontPoppin;
    font-weight: 500;
    border: 0;
    cursor: pointer;
    display: flex;
    line-height: 1;
    gap: 1rem;
    @apply shadow;
    &:hover{
        @apply shadow-md;
    }
}

// FORMAT
.btn--rounded--default {
    border-radius: .5em;    
}
.btn--rounded {
    border-radius: 3em;
}
.btn--icon {
    padding: 11px 11px;
    border-radius: 100%;
}

// COLORS
.btn--default {
    color: $primary;
    background-color: transparent;
    border: $primary solid .1rem;
}
.btn--primary {
    color: white;
    background-color: $primary;
}
.btn--secondary {
    color: white;
    background-color: $secondary;
}
.btn--warning {
    color: $primary;
    font-weight: 700;
    background-color: $warning;
}
.btn--danger {
    color: white;
    background-color: $danger;
}
.btn--success {
    color: white;
    background-color: $successDark;
}
.btn--white {
    color: $default;
    background: white;
}
.btn--disabled {
    color: #92A0A9;
    background: $bgDisabled;
    cursor: not-allowed;
    @apply shadow-none;
    &:hover{
        @apply shadow-none;
    }
}


// SIZE
.btn--small {
    // font-size: 12px;
    padding: 10px 16px;
}
.btn--medium {
    // font-size: 14px;
}
.btn--large {
    // font-size: 16px;
    padding: 12px 24px;
}
.btn--full {
    // font-size: 14px;
    width: 100%;
    padding: 12px 24px;
}

button.ant-float-btn-default .ant-float-btn-body {
    background-color: $primary;
    transition: background-color 0.2s;
    .ant-float-btn-content{
        .ant-float-btn-icon{
            svg{
                fill: white;
            }
        }
    }
}
