@import './variables.scss';

/* TEXT COLOR CLASSES */

.text--primary{
    color: $primary;
}
.text--secondary{
    color: $secondary;
}
.text--success{
    color: $success;
}
.text--danger{
    color: $danger;
}
.text--default{
    color: $default;
}
.text--white{
    color: white;
}
.text--grey{
    color: $grey;
}
.text--yellow{
    color: $yellow;
}