// PRIMARY COLORS
$primary: #00426D;
$primaryDark: #003557;
$primaryLight: #5688A7;

// SECONDARY COLORS
$secondary: #0896B5;
$secondaryDark: #067891;
$secondaryLight: #A4DCE8;
$secondaryExtraLight: #D1F0F7;

// CORAIL COLORS
$corail: #FF7061;
$corailDark: #D75B4E;
$corailLight: #FFD4D0;

// YELLOW COLORS
$yellow: #F2CF1C;
$yellowDark: #C2A616;
$yellowLight: #FBF1BB;

// GREEN COLORS
$green: #87C2A8;
$greenDark: #6EA08A;
$greenLight: #DBEDE5;

// WARNING COLORS
$danger: #FF9595;
$dangerDark: #FF4E4E;
$dangerLight: #FFDCDC;
$dangerExtraLight: #fbe8e8;

$success: #91DDC2; // #91DDC2
$successDark: #47C799;
$successLight: #DAF4EB;
$successExtraLight: #edfdf7;

$warning: #FFCF87;
$warningDark: #FFAF37;
$warningLight: #FFEFD7;

$default: #1A2328;
$subTitle: #6C747A;

$grey: #6C747A;
$greyDark: #1A2328;
$greyLight: #dddfe1;

$bgDisabled: #f1f4f6;

$backgroundApp: #F7F5F2; // F0F2F3 F7F5F2
$backgroundSidebar: white;
$selectedNavBackground: hsl(191deg 92% 37% / 24%);

// shadow 
$shadowPrimary: rgb(68 132 171 / 36%);

// Fonts
$fontDefault: 'Poppin', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
$fontPoppin: 'Poppin', 'Roboto', 'Segoe UI', source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
$fontPoppinMedium: 'Poppin-medium', 'Roboto', 'Segoe UI', source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
$fontRoboto: 'Roboto', 'Segoe UI', 'Segoe UI', source-code-pro, Menlo, Monaco, Consolas, monospace;
$fontCode: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

// TEXTES SIZES
$mainHeading: 32px; // H1
$secondaryTitle: 28px; // H2
$tertiaryTitle: 24px; // H3
$fourthTitle: 20px; // H4
$fifthTitle: 18px; // H5
$sixthTitle: 16px; // H6

$textDefault: 14px;
$textMobile: 16px;