@import './variables.scss';

/* Input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.ant-input{
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px !important;
    height: 45px;
    gap: 16px;
    font-family: $fontPoppin;

    /* NEUTRES/Blanc */

    background: #FFFFFF;
    /* NEUTRES/Noir 300 */

    border: 1px solid #DEE2E6;
    border-radius: 6px;

    /* Inside auto layout */

    // color: $primary;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    box-shadow: 0px -1px 0px 0px rgba(20, 16, 36, 0.08) inset, 0px 1px 0px 0px rgba(245, 245, 245, 0.12) inset, 0px 2px 4px 0px rgba(20, 16, 36, 0.04);
}

input.ant-input.form{
    max-width: 130px;
}

.digits input.ant-input{
    height: 85px;
}

// input.ant-input:focus, input.ant-input:hover, input.ant-input:focus-within {
//     border-color: $primary;
//     outline: 0;
// }

// span.ant-input-affix-wrapper:hover, span.ant-input-affix-wrapper:focus {
//     border-color: $primary;
// }

.ant-input-number {
    border: none;
    background-color: transparent;
    .ant-input-number-handler-wrap{
        border-radius: 0 16px 16px 0;
        border: .4px solid #DEE2E6;
    }
    input.ant-input-number-input{
        box-sizing: border-box;
        height: auto;
    
        /* Auto layout */
    
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px 16px !important;
        gap: 16px;
    
        /* NEUTRES/Blanc */
    
        background: #FFFFFF;
        /* NEUTRES/Noir 300 */
    
        border: 1px solid #DEE2E6;
        border-radius: 16px;
    
        /* Inside auto layout */
    
        color: $primary;
    
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }
}

.ant-input-number-focused, .ant-input-number:focus{
    border: none;
    box-shadow: none;
}

.ant-form-item-label>label{
    font-family: $fontPoppin;
    min-width: 100%;
    color: $default !important;
}

.form-error .help-block{
    padding: 0.5rem 0;
    color: $danger;
}

span.ant-input-affix-wrapper{
    width: 100%;
    display: flex;
    border-radius: 6px;
    flex-flow: row-reverse;
    padding: 0 12px;
    .ant-input-prefix{

    }
    input{
        flex-shrink: 1;
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

div.ant-picker:hover {
    border-color: $primary;
}

div.ant-picker-focused.ant-picker, div.ant-picker-.ant-picker{
    border-color: $primary;
}

div.ant-picker .ant-picker-input >input {
    height: 40px;
}

span.ant-input-affix-wrapper:focus, span.ant-input-affix-wrapper:focus-within{
    border-color: $primary;
}
div.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: $greyLight
}
// label.ant-radio-wrapper{
//     font-size: 16px;
// }

button.ant-btn.ant-btn-sm {
    color: $primary;
    border: 1px solid;
}
button.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover{
    color: white;
    background-color: $primary;
    border: none;
}
div.ant-picker-dropdown .ant-picker-time-panel-column >li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: $greyLight;
}
div.ant-select .ant-select-selection-item{
    font-size: 14px;
}